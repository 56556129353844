import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

export const CollapsedWrapper = withStyles(() => ({
  root: {
    fontSize: '.9375em',
    padding: '0 2em 1em',
    '& > div': {
      '&:first.child': {
        paddingTop: '1.25rem',
      },
      '&:last.child': {
        paddingBottom: '0',
      },
    },
  },
}))(Box);

export default CollapsedWrapper;
