import React, { useState } from 'react';
import { Avatar, Box, Tooltip, Zoom } from '@material-ui/core';
import { SupervisedUserCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  avatarWrapper: {
    margin: 0,
    '@media (max-width:599px)': {
      margin: 0,
      alignItems: 'center',
    },
  },
  avatar: {
    maxWidth: '100%',
    maxHeight: '100%',
    border: '1px #ddd solid',
  },
  initials: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    fontSize: '1.25em',
  },
  iconWrapper: {
    marginTop: 0,
    marginLeft: 1,
  },
  icon: {
    backgroundColor: '#FFF0',
    color: theme.palette.primary.light,
  },
}));

export const AvatarUI = ({ user, size = 40 }) => {
  const styles = useStyles();
  const initials = (user.name.charAt(0) + user.surname.charAt(0)).toUpperCase();

  const css = {
    height: size,
    width: size,
  };

  return (
    <Box className={styles.avatarWrapper} style={css}>
      {user.profilePhoto ? (
        <Avatar
          alt="User"
          className={styles.avatar}
          src={user.profilePhoto}
        />
      ) : (
        <Avatar className={styles.initials} style={css}>{initials}</Avatar>
      )}
    </Box>
  );
};

export const AvatarTooltipUI = ({ user, size = 40 }) => {
  const title = `${user.name} ${user.surname}`;

  const [tooltipActive, setTooltipActive] = useState(false);

  const handleShowTooltip = () => setTooltipActive(true);
  const handleHideTooltip = () => setTooltipActive(false);

  return (
    <Tooltip
      arrow
      title={title}
      TransitionComponent={Zoom}
      placement="bottom"
      open={tooltipActive}
      onOpen={handleShowTooltip}
      onClose={handleHideTooltip}
    >
      <span>
        <AvatarUI user={user} size={size} />
      </span>
    </Tooltip>
  );
};

export const AvatarGroupIcon = ({ size }) => {
  const styles = useStyles();

  const css = {
    fontSize: `${size * 1.24}px`,
    margin: '-5px -4px',
  };

  return <SupervisedUserCircle className={styles.icon} style={css} />;
};

export const AvatarGroupTooltipUI = ({ group, size = 40 }) => {
  const styles = useStyles();
  const title = group && group.name;

  const [tooltipActive, setTooltipActive] = useState(false);

  const handleShowTooltip = () => setTooltipActive(true);
  const handleHideTooltip = () => setTooltipActive(false);

  return (
    <Tooltip
      arrow
      title={title}
      TransitionComponent={Zoom}
      placement="bottom"
      open={tooltipActive}
      onOpen={handleShowTooltip}
      onClose={handleHideTooltip}
    >
      <span className={styles.iconWrapper}>
        <AvatarGroupIcon size={size} />
      </span>
    </Tooltip>
  );
};
